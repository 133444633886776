//== Set defaults
window.swal = swal.mixin({
  width: 400,
  padding: '2.5rem',
  buttonsStyling: false,
  confirmButtonClass: 'btn btn-success m-btn m-btn--custom',
  confirmButtonColor: null,
  cancelButtonClass: 'btn btn-secondary m-btn m-btn--custom',
  cancelButtonColor: null
});
